import { Utilities } from "./utilities";
import { Vehicle } from "../interface/vehicle";
import { State } from "../interface/state";
import { ValidatorFn } from "@angular/forms";
import { fuelTypes } from '../shared/lists/fuel-types';
import { environment } from "../../environments/environment";
import { MERCOSUL_PLATES, OTHER_PLATES } from "../shared/lists/plate-types";


export class VehicleUtilities extends Utilities {

  static isDefault(plate: string): boolean {
    return plate === environment.DEFAULT_VEHICLE_PLATE
  }

  static changed(a: Vehicle, b: Vehicle): boolean {
    return !this.equalString(a.plate, b.plate)
      || !this.equalString(a.client, b.client)
      || !this.equalString(a.km, b.km)
      || !this.equalString(a.model, b.model)
      || !this.equalString(a.brand, b.brand)
      || !this.equalString(a.motor, b.motor)
      || !this.equalString(a.year, b.year)
      || !this.equalString(a.modelYear, b.modelYear)
      || !this.equalString(a.color, b.color)
      || !this.equalString(a.vin, b.vin)
      || !this.equalString(a.state, b.state)
      || !this.equalString(a.city, b.city)
      || !this.equalString(a.fuel, b.fuel);
  }

  /** The complyApp function need be invoked before set the TMA object*/
  static complyApp(vehicle: Vehicle, states: State[]): Vehicle {
    if(vehicle.year){
      vehicle.year = this.removeMask(vehicle.year).substring(0, 4);
    }
    if(vehicle.modelYear){
      vehicle.modelYear = this.removeMask(vehicle.modelYear).substring(0, 4);
    }
    if(vehicle.brand){
      vehicle.brand = vehicle.brand.toUpperCase()
    }
    if(vehicle.model){
      vehicle.model = vehicle.model.toUpperCase()
    }
    if (vehicle.state && states) {
      const vehicleState = vehicle.state.toLowerCase();
      const vehicleCity = Utilities.removeDiacritics(vehicle.city).toLowerCase();
      for (let state of states) {
        if (state.code && state.code.toLowerCase() == vehicleState) {
          vehicle.state = state.code;
          for (let city of state.cities) {
            if (city.name && Utilities.removeDiacritics(city.name).toLowerCase() == vehicleCity) {
              vehicle.city = city.name;
              break;
            }
          }
          break;
        }
      }
    }
    /** remove unknown fuel type */
    if (vehicle.fuel) {
      let isIn = false
      fuelTypes.forEach(fuelType => {
        if (vehicle.fuel == fuelType.code) {
          isIn = true;
        }
      });
      if (!isIn) {
        vehicle.fuel = undefined;
      }
    }

    if(vehicle.km){
      vehicle.km = this.removeMask(vehicle.km);
    }
    return vehicle;
  }

  static complyApi(vehicle: Vehicle) {
    console.log(vehicle)
    let resp: Vehicle = { ...vehicle };
    resp['available'] = (vehicle && Number.isInteger(vehicle.available) ? vehicle.available : 1);
    const requiredFields =
      ['brand', 'model', 'color', 'year', 'modelYear', 'vin', 'state', 'city']
    for (const field of requiredFields) {
      if (!resp[field]) {
        resp[field] = '-' // avoid required errors
      }
    }
    for (const field of Object.keys(resp)) {
      if (resp[field] === null || resp[field] === undefined || resp[field] === "") {
        delete resp[field]
      }
    };

    delete resp['id']
    delete resp["vehicleHistory"];
    delete resp["clientObject"];
    delete resp['kmAveragePerMonth']
    resp.client = ((vehicle.client as any)?.id ?? vehicle.client);
    resp.lastUpdate = new Date().toISOString();
    return resp;
  }

  static getPlate(plate: string): string {
    return plate.toUpperCase().replace(/[^A-Z0-9]/g, '');
  }

  static getPlatePrefix(plate: string): string {
    plate = this.getPlate(plate);
    let prefix = '';
    for (const c of plate) {
      if ((/^[A-Z]$/.test(c) && (prefix.length < 3 || prefix.length == 4))
        || (/^\d$/.test(c) && prefix.length >= 3)
      ) {
        prefix += c;
      }
    }
    return prefix;
  }

  static formatPlate(text: string): string {
    return text?.toUpperCase().replace(/[^A-Z0-9]/g, '') || "";
  }

  static splitBrandAndModel(vehicle: Vehicle) {
    const special = ['I', 'IMP', 'R', 'REB'];
    let text = vehicle.brand.trim();
    while (true) {
      const index = text.indexOf('/');
      if (index < 0) {
        break;
      }
      vehicle.brand = text.substring(0, index).trim();
      vehicle.model = text.substring(index + 1).trim();
      if (!special.includes(vehicle.brand.toUpperCase())) {
        return;
      }
      text = vehicle.model;
    }
    const index = text.indexOf(' ');
    if (index >= 0) {
      vehicle.brand = text.substring(0, index).trim();
      vehicle.model = text.substring(index + 1).trim();
    } else {
      vehicle.brand = text;
      vehicle.model = '';
    }
  }

  static validatePlate(): ValidatorFn {
    return control => {
      if(!control){
        return null
      }
      const value = this.formatPlate(control.value || "");
      if (VehicleUtilities.isDefault(value)) {
        return { internal: true }
      }
      if (value != control.value) {
        control.setValue(value);
      }
      if (!value) {
        return null;
      }
      for (let mp in MERCOSUL_PLATES) {
        if (MERCOSUL_PLATES[mp].test(value)) {
          return null;
        }
      }
      for (let op in OTHER_PLATES) {
        if (OTHER_PLATES[op].test(value)) {
          return null;
        }
      }
      return { plate: { value: control.value } };
    };
  }

}
