import { Injectable } from '@angular/core';
import { Vehicle } from '../interface/vehicle';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { filter, first, take } from 'rxjs/operators';
import { DataService } from './data.service';
import { VehicleUtilities } from '../class/vehicle-utilities';
import { LocalStorageService } from './local.storage.service';
import { AddressService } from './address.service';
import { State } from '../interface/state';
import { firstValueFrom } from 'rxjs';
import { Utilities } from '../class/utilities';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private _isFromMatrix = true;
  private vehicles = new Map<string, Map<string, Vehicle>>();
  private timestamps = new Map<string, number>();
  states: State[] = []

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    addressService: AddressService
  ) {
    this.dataService.company$.pipe(filter(c => !!c)).subscribe(() =>
      addressService.getStates().then(states => this.states = states)
    );
  }

  async getDefaultId(): Promise<string> {
    const fromLocalStorage = this.localStorageService.get('DEFAULT_VEHCILE_ID')
    // const fromLocalStorage = localStorage.getItem('DEFAULT_VEHCILE_ID');
    if (fromLocalStorage) {
      return fromLocalStorage
    }
    const defaulVehicle = await this.getByPlate(environment.DEFAULT_VEHICLE_PLATE);
    this.localStorageService.set('DEFAULT_VEHICLE_ID', defaulVehicle.id)
    // localStorage.setItem('DEFAULT_VEHICLE_ID', defaulVehicle.id);
    return defaulVehicle.id
  }

  async get(id: string): Promise<Vehicle> {
    const url = `${environment.mkgoURL}/api/v1/vehicles/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const vehicle: Vehicle = await this.http.get(url, header).pipe(first())
      .toPromise();
    return VehicleUtilities.complyApp(vehicle, this.states);
  }

  async getByPlate(plate: string): Promise<Vehicle> {
    const formtedPlate = Utilities.removeSpecialCharacters(plate).toUpperCase();
    const url = `${environment.mkgoURL}/api/v1/vehicles/plate/${formtedPlate}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const vehicle: Vehicle = await this.http.get(url, header).pipe(first()).toPromise();
    return VehicleUtilities.complyApp(vehicle, this.states);
  }

  async getByPlateInfocar(plate: string): Promise<Vehicle> {
    const url = `${environment.mkgoURL}/api/v1/vehicles/infocar/${plate}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const vehicle: Vehicle = await this.http.get(url, header).pipe(first()).toPromise();
    const appVehicle = VehicleUtilities.complyApp(vehicle, this.states);
    return appVehicle;
  }


  /** @todo refactor */
  async getAll(withDefault?: boolean): Promise<Map<string, Vehicle>> {
    const company = await firstValueFrom(this.dataService.company$.pipe(filter((c) => !!c)));
    let timestamp = this.timestamps.get(company.id) || 0;
    const url = `${environment.mkgoURL}/api/v1/vehicles`;
    const options = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    options.headers = options.headers.set('timestamp', timestamp.toString())
    const response: any = await this.http.get(url, options).pipe(first()).toPromise();
    const vehicles = this.vehicles.get(company.id) || new Map<string, Vehicle>();
    this.vehicles.set(company.id, vehicles);
    ((response.vehicles || []) as Vehicle[]).reverse().forEach(vehicle => {
      VehicleUtilities.complyApp(vehicle, this.states);
      timestamp = Math.max(timestamp, new Date(vehicle.lastUpdate || 0).getTime());
      if (VehicleUtilities.isDefault(vehicle.id)) {
        if (withDefault) {
          vehicles.set(vehicle.id, vehicle);
        }
      } else {
        vehicles.set(vehicle.id, vehicle);
      }
    });
    this.timestamps.set(company.id, timestamp);
    return vehicles;
  }

  async add(vehicle: Vehicle): Promise<string> {
    let apiVehicle = VehicleUtilities.complyApi(vehicle);
    const url = `${environment.mkgoURL}/api/v1/vehicles`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.post(url, apiVehicle, header).pipe(first()).toPromise();
    this.timestamps.set(this.dataService.company.id, 0);
    return resp['id'];
  }

  async update(vehicle: Vehicle): Promise<string> {
    const apiVehicle = VehicleUtilities.complyApi(vehicle);
    const url = `${environment.mkgoURL}/api/v1/vehicles/${vehicle.id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.put(url, apiVehicle, header).pipe(first()).toPromise();
    this.timestamps.set(this.dataService.company.id, 0);
    return resp['id']
  }

}
